import React from "react";

export const IconExperience = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 783 603" style={{ enableBackground: 'new 0 0 783 603' }} xmlSpace="preserve">
    <g>
      <g>
        <path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#000000' }}
          d="M236.1,162.7H637c0,0-145.9,44.9-271,121.8c0,0,216.6-29,347.3,73.9c0,0-105.6-44.9-277.9-31.7 C263.1,340.5,76.2,437.9,76.2,437.9s-15.2,8.1-4.5-5.7c0,0,176.2-161,358.1-241.1H237.1c0,0-18.3-1.2-44.9,12L236.1,162.7z" />
      </g>
    </g>
  </svg>

);

/*
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 443.71 444.6">
    <g id="katman_2" data-name="katman 2">
      <g id="katman_1-2" data-name="katman 1">
        <path d="M377.3,197.09l-118.8,1.09L62.51,200a159,159,0,0,1,263.38-97H258.5v54.66L365.4,157A154.1,154.1,0,0,1,377.3,197.09Z" />
        <path d="M258.5,286.74v53.58h67.58A159,159,0,0,1,62.24,240.59l196.26,1.82,119.16,1.09a152.6,152.6,0,0,1-12.26,43Z" />
        <path d="M221.44,0C106.12,0,11,88.37.28,201l27.37-.33C38.44,103.17,121.11,27.23,221.44,27.23c107.7,0,195,87.39,195,195.09a195,195,0,0,1-195,195c-101.54,0-184.92-77.53-194.07-176.64H0c9.29,114,105.09,203.92,221.44,203.92,122.51,0,222.27-99.72,222.27-222.28S344,0,221.44,0Z" />
      </g>
    </g>
  </svg>
*/
