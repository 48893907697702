import axios from "axios";
import { config } from "../../config";

export default class ApiService {
  async login(email, password) {
    try {
      const result = await axios.post(`${config.api}/auth/local`, {
        identifier: email,
        password: password,
      });

      localStorage.setItem("mydonzi:user", JSON.stringify(result.data));

      return true;
    } catch (e) {
      return false;
    }
  }

  async forgot_password(email) {
    try {
      await axios.post(`${config.api}/my-donzi/users/forgot-password`, {
        email,
      });

      return true;
    } catch (e) {
      return true;
    }
  }

  async forgot_password_complete(token, password) {
    try {
      await axios.post(
        `${config.api}/my-donzi/users/forgot-password-complete`,
        {
          token,
          password,
        }
      );

      return true;
    } catch (e) {
      return false;
    }
  }

  async registration_complete(token, password) {
    try {
      await axios.post(
        `${config.api}/my-donzi/users/complete-registration`,
        {
          token,
          password,
        }
      );

      return true;
    } catch (e) {
      return false;
    }
  }

  async getUser() {
    const user = localStorage.getItem("mydonzi:user");

    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  async checkUser() {
    // check token
    // check api
    const user = localStorage.getItem("mydonzi:user");

    if (user) {
      // check user
      return true;
    } else {
      return false;
    }
  }

  async logout() { }

  async getYacht() {
    console.log(window.location);

    const auth = await this.getUser();
    let reload = false;

    if (window.location.search.indexOf("r=1") > -1) reload = true;

    const result = await axios.post(
      `${config.api}/my-donzi/yacht${reload ? "?reload=1" : ""}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
        },
      }
    );

    return result.data;
  }

  async getServiceRequests() {
    const auth = await this.getUser();
    console.log(auth);

    const result = await axios.get(
      `${config.api}/my-donzi/service-requests`,
      {
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
        },
      }
    );

    return result.data;
  }

  async createServiceRequest(data) {
    const auth = await this.getUser();

    const result = await axios.post(
      `${config.api}/my-donzi/service-request`,
      data,
      {
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
        },
      }
    );

    return result.data;
  }
}
