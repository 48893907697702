export const NotFound = () => {
  return (
    <main className="dashboard">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="sheet">
              <div className="page-header">
                <div className="title-wrapper">
                  <div className="title-sub"></div>
                  <div className="title" style={{ fontWeight: "bold" }}>
                    Account not found!
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 30 }}>
                Please contact with Donzi Team.
              </div>

              <div style={{ marginTop: 15 }}>
                <a href="mailto:sales@donzimarine.com">Mail us!</a> |{" "}
                <a href="tel:+1 954 759 9900">Call us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
