import { Page } from "../../components/Page";
import { Sheet } from "../../components/Sheet";
import IconFile from "../../../assets/file.svg";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/context";
import { config } from "../../../config";

function compare(a, b) {
  let first = formatName(a.name);
  let second = formatName(b.name);

  if (first.order < second.order) return -1;
  if (first.order > second.order) return 1;
  return 0;
}

const formatName = (name) => {
  if (name.indexOf("_") < 0) return { order: 0, name };
  let parts = name.split("_");

  return { order: +parts[0], name: parts.slice(1).join(" ") };
};

export const AboutWarranty = observer(() => {
  const { appStore } = useStores();

  return (
    <Page active="about.warranty">
      <Sheet title="About My Donzi" subtitle="My Documents">
        <div className="list-wrapper">
          {appStore.yacht.Sales_Agreement && (
            <div className="warranty-items">
              <a
                className="warranty-item"
                href={`${config.api}/my-donzi/FieldAttachment/Yachts/${appStore.yacht.id}/${appStore.yacht.Sales_Agreement[0].attachment_Id}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text">Sales Agreement</div>
                <div className="icon">
                  <img src={IconFile} alt="" />
                </div>
              </a>
            </div>
          )}

          {appStore.yacht.Owner_Delivery_Experience_Documents && (
            <div className="warranty-items">
              <a
                className="warranty-item"
                href={`${config.api}/my-donzi/FieldAttachment/Yachts/${appStore.yacht.id}/${appStore.yacht.Owner_Delivery_Experience_Documents[0].attachment_Id}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text">Owner Delivery Experience Documents</div>
                <div className="icon">
                  <img src={IconFile} alt="" />
                </div>
              </a>
            </div>
          )}

          {appStore.yacht.Warranty_Certificate_PDF && (
            <div className="warranty-items">
              <a
                className="warranty-item"
                href={`${config.api}/my-donzi/FieldAttachment/Yachts/${appStore.yacht.id}/${appStore.yacht.Warranty_Certificate_PDF[0].attachment_Id}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text">Warranty Certificate</div>
                <div className="icon">
                  <img src={IconFile} alt="" />
                </div>
              </a>
            </div>
          )}

          {appStore.yacht_attachments.sort(compare).map((x, i) => (
            <div className="warranty-items" key={i}>
              <a
                className="warranty-item"
                href={`${x.url}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text">{formatName(x.name).name}</div>
                <div className="icon">
                  <img src={IconFile} alt="" />
                </div>
              </a>
            </div>
          ))}
        </div>
      </Sheet>
    </Page>
  );
});
