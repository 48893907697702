import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Bookmark } from "./app/components/Bookmark";
import { Footer } from "./app/components/Footer";
import { Header } from "./app/components/Header";
import { Loading } from "./app/components/Loading";
import { useStores } from "./app/stores/context";
import { NotFound } from "./app/components/NotFound";

const App = observer(() => {
  const navigate = useNavigate();
  const { appStore } = useStores();

  const init = async () => {
    await appStore.fetch();
  };

  useEffect(() => {
    const user = localStorage.getItem("mydonzi:user");

    if (!user) {
      navigate("/login");
    } else {
      // get data
      init();
    }
  });

  if (appStore.loading) return <Loading />;

  return (
    <div className="my-donzi">
      <Header />
      <div className="wrapper">
        {appStore.not_found ? <NotFound /> : <Outlet />}
      </div>
      {!appStore.not_found && <Bookmark />}
      <Footer />
    </div>
  );
});

export default App;
