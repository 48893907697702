/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import logo from "../../../logo.svg";
import background from "../../../assets/img/login.png";
import { Loading } from "../../components/Loading";
import ApiService from "../../services/api.service";

export const Login = () => {
  const apiService = new ApiService();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email || !password) {
      alert("Please provide email / password");
      return;
    }

    const result = await apiService.login(email, password);

    if (result) {
      window.location.href = "/";
    } else {
      setLoading(false);
      setError("Invalid username / password");
    }
  };

  const forgotPassword = async (e) => {
    e.preventDefault();

    setShowPassword(!showPassword);
  };

  const onForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    const result = await apiService.forgot_password(email);

    if (result) {
      setForgotPasswordSuccess("Please check your mailbox.");
      setLoading(false);
    } else {
      setForgotPasswordSuccess("Please check your mailbox.");

      setLoading(false);
      setError("");
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="my-donzi">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="col-md-10"></div>
          </div>
        </div>
      </div>
      <div
        className="wrapper"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showPassword ? (
          <form onSubmit={onForgotPassword}>
            <div className="login-form">
              <h3>Forgot Password</h3>
              <div className="login-title" style={{ marginBottom: 10 }}>
                {/* <div className="login-title-sm font-alt">Welcome to</div> */}
                <div className="login-title-sm font-alt">
                  Please enter your email.
                </div>
              </div>
              <div className="login-content">
                <div className="login-line">
                  <input
                    type="email"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="login-line" style={{ marginTop: 15 }}>
                  <button
                    type="submit"
                    className="button-dark"
                    onClick={onForgotPassword}
                  >
                    Reset
                  </button>
                </div>
                {error && (
                  <div
                    className="error"
                    style={{
                      color: "indianred",
                      textAlign: "center",
                      marginTop: 10,
                      fontSize: 12,
                    }}
                  >
                    {error}
                  </div>
                )}

                {forgotPasswordSuccess && (
                  <div
                    className="success"
                    style={{
                      color: "green",
                      textAlign: "center",
                      marginTop: 10,
                      fontSize: 12,
                    }}
                  >
                    {forgotPasswordSuccess}
                  </div>
                )}
              </div>
              <div className="login-line" style={{ marginTop: 25 }}>
                <span style={{ fontSize: 10 }}>To Login</span>{" "}
                <strong>
                  <a href="#" onClick={forgotPassword}>
                    Click here
                  </a>
                </strong>
              </div>
            </div>
          </form>
        ) : (
          <form onSubmit={login}>
            <div className="login-form">
              <h3>Login</h3>
              <div className="login-title">
                <div className="login-title-sm font-alt">Welcome to</div>
                <div className="login-title-md font-alt">My Donzi</div>
              </div>
              <div className="login-content">
                <div className="login-line">
                  <input
                    type="email"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="login-line">
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="login-line" style={{ marginTop: 15 }}>
                  <button type="submit" className="button-dark" onClick={login}>
                    Login
                  </button>
                </div>
                {error && (
                  <div
                    className="error"
                    style={{
                      color: "indianred",
                      textAlign: "center",
                      marginTop: 10,
                      fontSize: 12,
                    }}
                  >
                    {error}
                  </div>
                )}

                {/* <div className="login-line" style={{ marginTop: 15 }}>
              <Link to="#">Sign Up</Link>
            </div> */}
                <div className="login-line" style={{ marginTop: 25 }}>
                  <span style={{ fontSize: 10 }}>Forgot password?</span>{" "}
                  <strong>
                    <a href="#" onClick={forgotPassword}>
                      Click here
                    </a>
                  </strong>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="footer">
        Trademark™️ &amp; Copyright®️ {new Date().getFullYear()} Donzi All rights
        reserved.
      </div>
    </div>
  );
};
