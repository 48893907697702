import React, { useState } from "react";
import { Link } from "react-router-dom";

// Icons
import { IconBoutique } from "../icons/IconBoutique";
import { IconBuildYours } from "../icons/IconBuildYours";
import { IconConfigurations } from "../icons/IconConfigurations";
import { IconDashboard } from "../icons/IconDashboard";
import { IconExperience } from "../icons/IconExperience";
import { IconOrderHistory } from "../icons/IconOrderHistory";
import { IconPaymentMethod } from "../icons/IconPaymentMethod";
import { IconProfile } from "../icons/IconProfile";
import { IconSignout } from "../icons/IconSignOut";

import IconSupport from "../../assets/support.svg";
import IconAbout from "../../assets/about.svg";
import IconUpgrade from "../../assets/upgrade.svg";

import { observer } from "mobx-react-lite";
import { useStores } from "../stores/context";

export const Sidenav = observer((props) => {
  const { appStore } = useStores();

  const [active, setActive] = useState();
  const [activeChild, setActiveChild] = useState();

  React.useEffect(() => {
    if (props.active) {
      try {
        let parts = props.active.split(".");
        setActive(parts[0]);
        setActiveChild(props.active);
      } catch (e) {}
    }
  }, [props.active]);

  const onActiveChanged = (name) => {
    setActive(name);
  };

  const onSignout = async (e) => {
    e.preventDefault();

    // await apiService.logout();

    window.location.href = "https://donzimarine.com/";
  };

  return (
    <div className="sheet">
      <div className="account-navigation" style={{ width: 300 }}>
        <ul className="tds-list navigation ">
          <li>
            About My Donzi
            <hr />
          </li>
          <li className="navigation-link">
            <Link to="/about/general">
              <div className="scrim">
                <IconExperience />
              </div>
              <span onClick={(e) => props.onSelect && props.onSelect()}>
                My Boat Information
              </span>
            </Link>
          </li>

          <li className="navigation-link">
            <Link to="/about/warranty">
              <div className="scrim">
                <IconConfigurations />
              </div>
              <span onClick={(e) => props.onSelect && props.onSelect()}>
                My Documents
              </span>
            </Link>
          </li>

          <li>
            <br />
            After Sales & Support
            <hr />
          </li>
          <li className="navigation-link">
            <Link to="/support/create">
              <div className="scrim">
                <IconPaymentMethod />
              </div>
              <span onClick={(e) => props.onSelect && props.onSelect()}>
                Schedule Service
              </span>
            </Link>
          </li>

          <li className="navigation-link">
            <Link to="/support">
              <div className="scrim">
                <IconProfile />
              </div>
              <span onClick={(e) => props.onSelect && props.onSelect()}>
                My Service Records
              </span>
            </Link>
          </li>

          <li>
            <hr />
          </li>

          <li className="navigation-link ">
            <Link to="/owner-manuals">
              <div className="scrim">
                <IconDashboard />
              </div>
              <span onClick={(e) => props.onSelect && props.onSelect()}>
                Manuals
              </span>
            </Link>
          </li>

          <li className="navigation-link disabled">
            {/* <Link to="/account/configurations"> */}
            <a href="/" onClick={(e) => e.preventDefault()}>
              <div className="scrim">
                <IconConfigurations />
              </div>{" "}
              <span onClick={(e) => props.onSelect && props.onSelect()}>
                Upgrade My Donzi
              </span>
            </a>
            {/* </Link> */}
          </li>

          <li className="navigation-link">
            <a
              href="https://wakeeffects.com/collections/shop-by-brand-donzi-marine"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="scrim">
                <IconBoutique />
              </div>
              <span onClick={(e) => props.onSelect && props.onSelect()}>
                Boutique
              </span>
            </a>
          </li>

          <li className="navigation-link ">
            <a href="#" onClick={onSignout}>
              <div className="scrim">
                <IconSignout />
              </div>
              Sign Out
            </a>
          </li>
        </ul>
      </div>
      {/* 
      <div className="sheet">
        <ul className="nav">
          <li className={`${active === "about" ? "active" : ""}`}>
            <div className={`parent`} onClick={() => onActiveChanged("about")}>
              <span className="icon">
                <img src={IconAbout} width={12} alt="" />
              </span>
              <span className="text">About My Donzi</span>
              <span className="indicator">X</span>
            </div>

            {active === "about" && (
              <div className="nav-child animate__animated ">
                <ul>
                  <li
                    className={activeChild === "about.general" ? "active" : ""}
                  >
                    <span className="sub-active">
                      {activeChild === "about.general" && "x"}
                    </span>
                    <Link to="/about/general">
                      <span onClick={(e) => props.onSelect && props.onSelect()}>
                        My Boat Information
                      </span>
                    </Link>
                  </li>
                  <li
                    className={activeChild === "about.warranty" ? "active" : ""}
                  >
                    <span className="sub-active">
                      {activeChild === "about.warranty" && "x"}
                    </span>
                    <Link to="/about/warranty">
                      <span onClick={(e) => props.onSelect && props.onSelect()}>
                        My Documents
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </li>

          <li className={`${active === "support" ? "active" : ""}`}>
            <div
              className={`parent`}
              onClick={(e) => onActiveChanged("support")}
            >
              <span className="icon">
                <img src={IconSupport} width={12} alt="" />
              </span>
              <span className="text">After Sales &amp; Support</span>
              <span className="indicator">X</span>
            </div>

            {active === "support" && (
              <div className="nav-child animate__animated ">
                <ul>
                  <li
                    className={activeChild === "support.create" ? "active" : ""}
                  >
                    <span className="sub-active">
                      {activeChild === "support.create" && "x"}
                    </span>
                    <Link to="/support/create">
                      <span onClick={(e) => props.onSelect && props.onSelect()}>
                        Schedule Service
                      </span>
                    </Link>
                  </li>
                  <li
                    className={activeChild === "support.list" ? "active" : ""}
                  >
                    <span className="sub-active">
                      {activeChild === "support.list" && "x"}
                    </span>
                    <Link to="/support">
                      <span onClick={(e) => props.onSelect && props.onSelect()}>
                        My Service Records
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </li>

          <li className={`${active === "manuals" ? "active" : ""}`}>
            <Link className="parent" to="/owner-manuals">
              <span className="icon">
                <img src={IconBoutique} width={12} alt="" />
              </span>
              <span className="text">
                <span onClick={(e) => props.onSelect && props.onSelect()}>
                  Manuals
                </span>
              </span>
            </Link>
          </li>

          <li className={`${active === "privileges" ? "active" : ""}`}>
            <a
              // href={appStore.configuration.Configuration_URL}
              href="/"
              onClick={(e) => e.preventDefault()}
              className={`parent disabled`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon">
                <img src={IconUpgrade} width={12} alt="" />
              </span>
              <span className="text">
                <span onClick={(e) => props.onSelect && props.onSelect()}>
                  Upgrade My Donzi
                </span>
              </span>
            </a>
          </li>

          <li className={`${active === "boutique" ? "active" : ""}`}>
            <a
              href="https://donzi-yachts.com/boutique"
              className={`parent`}
              // href="/"
              // onClick={(e) => e.preventDefault()}
              onClick={() => onActiveChanged("boutique")}
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon">
                <img src={IconBoutique} width={12} alt="" />
              </span>
              <span className="text">
                <span onClick={(e) => props.onSelect && props.onSelect()}>
                  Boutique
                </span>
              </span>
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
});
