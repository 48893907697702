import logo from "../../../logo.svg";
import background from "../../../assets/img/login.png";
import { useParams } from "react-router-dom";
import { useState } from "react";
import ApiService from "../../services/api.service";
import { Loading } from "../../components/Loading";

export const Complete = () => {
  const { token } = useParams();
  const apiService = new ApiService();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!password || !password2) {
      alert("Please provide a password");
      return;
    }

    if (password !== password2) {
      alert("Passwords are different");
      return;
    }

    const result = await apiService.registration_complete(token, password);

    if (result) {
      window.location.href = "/";
    } else {
      setLoading(false);
      setError("Invalid request. Please contact to Donzi sales team.");
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="my-donzi">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="col-10"></div>
          </div>
        </div>
      </div>
      <div
        className="wrapper"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form onSubmit={login}>
          <div className="login-form">
            <div className="login-title">
              <div className="login-title-sm font-alt">Welcome to</div>
              <div className="login-title-md font-alt">My Donzi</div>
            </div>
            <div className="login-content">
              <div className="login-line">
                <input
                  type="password"
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="login-line">
                <input
                  type="password"
                  placeholder="New Password Again"
                  onChange={(e) => setPassword2(e.target.value)}
                />
              </div>
              <div className="login-line" style={{ marginTop: 15 }}>
                <button type="submit" className="button-dark" onClick={login}>
                  Complete Registiration
                </button>
              </div>
              {error && (
                <div
                  className="error"
                  style={{
                    color: "indianred",
                    textAlign: "center",
                    marginTop: 10,
                    fontSize: 12,
                  }}
                >
                  {error}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="footer">
        Trademark™️ &amp; Copyright®️ {new Date().getFullYear()} Donzi All rights
        reserved.
      </div>
    </div>
  );
};
