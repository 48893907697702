/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "../../logo.svg";
import icon from "../../assets/icon.svg";
import profile from "../../assets/profile.png";
import { Link } from "react-router-dom";
import { useStores } from "../stores/context";
import { observer } from "mobx-react-lite";
import { NavIcon } from "./NavIcon";
import { MobileNav } from "./MobileNav";
import React from "react";

export const Header = observer(() => {
  const { appStore } = useStores();

  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-2 col-md-1 nav-icon">
            <NavIcon
              type="9"
              open={mobileNavOpen}
              onClick={(e) => {
                setMobileNavOpen(e);
              }}
            />

            <MobileNav
              active={mobileNavOpen}
              user={appStore.contact.Full_Name}
              logout={appStore.logout}
              onClose={() => setMobileNavOpen(false)}
            />
          </div>

          <div className="col-8 col-md-2 align-items-center">
            <div className="logo">
              <Link to="/">
                <span onClick={(e) => setMobileNavOpen(false)}>
                  <img src={logo} height={35} alt="My Donzi" />
                </span>
              </Link>
            </div>
          </div>
          <div className="col-2 col-md-9 align-items-center">
            <div className="buttons hide-mobile">
              <div className="website">
                <a
                  href="https://donzimarine.com/"
                  alt="Donzi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon} alt="donzi" width={20} /> donzimarine.com
                </a>
              </div>
              {/* <div className="notification">
                <a href="#">
                  <img src={bell} />
                </a>
              </div> */}
              <div className="profile">
                {/* <a href="#" style={{ backgroundImage: `url(${arrow})` }}> */}
                <a href="#">
                  <img src={profile} alt="profile" />{" "}
                  {appStore.contact.Full_Name}
                </a>
              </div>

              <div className="logout">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    appStore.logout();
                  }}
                  style={{
                    fontSize: 12,
                    textDecoration: "underline",
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
