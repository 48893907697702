import { observer } from "mobx-react-lite";
import { Page } from "../../components/Page";
import { Sheet } from "../../components/Sheet";
import { useStores } from "../../stores/context";

export const AboutGeneral = observer(() => {
  const { appStore } = useStores();

  return (
    <Page active="about.general">
      <Sheet title="About My Donzi" subtitle="General Information">
        <div className="form-wrapper">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-item">
                <label>Model</label>
                <input type="text" value={appStore.model.Name} disabled />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <label>Year</label>
                <input type="text" value={appStore.yacht.Model_Year} disabled />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <label>HIN Number</label>
                <input
                  type="text"
                  value={appStore.yacht.Limitless_Hull_Codes}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <label>HULL Number</label>
                <input type="text" value={appStore.yacht.Name} disabled />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <label>Configuration ID</label>
                <input
                  type="text"
                  value={appStore.configuration && appStore.configuration.Name}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-item">
                <label>Location</label>
                <input type="text" value={appStore.yacht.Location} disabled />
              </div>
            </div>
          </div>
        </div>
      </Sheet>

      <Sheet subtitle="Technical Specifications">
        <div className="form-wrapper">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Length</div>
                <div className="spec-text">{appStore.model.Length}</div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Beam</div>
                <div className="spec-text">{appStore.model.Beam}</div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Beam (open Side Terraces)</div>
                <div className="spec-text">
                  {appStore.model.Beam_with_open_Side_Terraces}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Draft</div>
                <div className="spec-text">{appStore.model.Draft}</div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Passengers</div>
                <div className="spec-text">{appStore.model.Passengers}</div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Displacement</div>
                <div className="spec-text">{appStore.model.Displacement}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Berths</div>
                <div className="spec-text">{appStore.model.Berths}</div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Fuel Cap.</div>
                <div className="spec-text">{appStore.model.Fuel_Capacity}</div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Range</div>
                <div className="spec-text">{appStore.model.Range}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Fresh Water Cap.</div>
                <div className="spec-text">
                  {appStore.model.Fresh_Water_Cap}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Generator Diesel Tank Cap.</div>
                <div className="spec-text">
                  {appStore.model.Generator_Diesel_Tank_Capacity}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="spec">
                <div className="spec-title">Classifications</div>
                <div className="spec-text">
                  {appStore.model.Classif_ications}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sheet>

      <Sheet subtitle="Paint Information">
        <div className="form-wrapper">
          {appStore.configuration &&
            appStore.configuration.Standard_Products &&
            appStore.configuration.Standard_Products.filter(
              (x) => x.Field === "Paint Information"
            ).map((x, i) => (
              <div className="row" key={i}>
                <div className="col-12 col-md-4">
                  <div className="spec">
                    <div className="spec-title">{x.Title}</div>
                    <div className="spec-text">{x.Brand}</div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="spec">
                    <div
                      className="spec-text montserrat"
                      style={{ fontSize: 12, marginTop: 18 }}
                    >
                      {x.Value}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Sheet>

      <Sheet subtitle="Decking Information">
        <div className="form-wrapper">
          {appStore.configuration &&
            appStore.configuration.Standard_Products &&
            appStore.configuration.Standard_Products.filter(
              (x) => x.Field === "Decking Information"
            ).map((x, i) => (
              <div className="row" key={i}>
                <div className="col-12 col-md-4">
                  <div className="spec">
                    <div className="spec-title">{x.Title}</div>
                    <div className="spec-text">{x.Brand}</div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="spec">
                    <div
                      className="spec-text montserrat"
                      style={{ fontSize: 12, marginTop: 18 }}
                    >
                      {x.Value} - {x.Serial_Number}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Sheet>

      <Sheet subtitle="Upholstery Information">
        <div className="form-wrapper">
          {appStore.configuration &&
            appStore.configuration.Standard_Products &&
            appStore.configuration.Standard_Products.filter(
              (x) => x.Field === "Upholstery Information"
            ).map((x, i) => (
              <div className="row" key={i}>
                <div className="col-12 col-md-4">
                  <div className="spec">
                    <div className="spec-title">{x.Title}</div>
                    <div className="spec-text">{x.Brand}</div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="spec">
                    <div
                      className="spec-text montserrat"
                      style={{ fontSize: 12, marginTop: 18 }}
                    >
                      {x.Value} - {x.Serial_Number}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Sheet>

      <Sheet subtitle="Cabin Information">
        <div className="form-wrapper">
          {appStore.configuration &&
            appStore.configuration.Standard_Products &&
            appStore.configuration.Standard_Products.filter(
              (x) => x.Field === "Cabin Information"
            ).map((x, i) => (
              <div className="row" key={i}>
                <div className="col-12 col-md-4">
                  <div className="spec">
                    <div className="spec-title">{x.Title}</div>
                    <div className="spec-text">{x.Brand}</div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="spec">
                    <div
                      className="spec-text montserrat"
                      style={{ fontSize: 12, marginTop: 18 }}
                    >
                      {x.Value} - {x.Serial_Number}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Sheet>

      <Sheet subtitle="Product Serial Numbers">
        <div className="form-wrapper">
          <div className="row">
            {appStore.configuration &&
              appStore.configuration.Standard_Products &&
              appStore.configuration.Standard_Products.filter(
                (x) => x.Field === "Product Serial Numbers"
              ).map((x, i) => (
                <div className="col-12" key={i}>
                  <div className="serialnumber">
                    <div className="serialnumber-title">{x.Title}</div>
                    <div className="serialnumber-number">{x.Serial_Number}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Sheet>
    </Page>
  );
});
