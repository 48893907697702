/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import profile from "../../assets/profile.png";
import icon from "../../assets/icon.svg";
import { Sidenav } from "./Sidenav";

export const MobileNav = (props) => {
  return (
    <div className={`mobile-nav ${props.active ? "active" : ""}`}>
      <div className="mobile-nav-inner">
        <div className="mobile-navigation">
          <Sidenav onSelect={() => props.onClose()} />
        </div>
        <div className="mobile-website">
          <div className="website">
            <a
              href="https://donzimarine.com/"
              alt="Donzi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon} alt="donzi" width={20} /> donzimarine.com
            </a>
          </div>
        </div>
        <div className="mobile-nav-footer">
          <div className="mobile-user">
            <img src={profile} alt="profile" />{" "}
            <span style={{ fontSize: 12, marginLeft: 10 }}>{props.user}</span>
          </div>
          <div className="mobile-logout">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                props.logout();
              }}
              style={{
                fontSize: 12,
                textDecoration: "underline",
                marginLeft: 10,
                cursor: "pointer",
              }}
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
